// Contact + menu

import './Home.scss';
import {data, lang, printData, resources} from '../common/data';
import github from '../res/github.png';
import linkedin from '../res/linkedin.png';
import en from '../res/en.png';
import fr from '../res/fr.png';
import App from './App';

// Icon buttons within the header
function IconButton(props) {
  let iconClass = props.iconOutlined ? "material-symbols-outlined" : "material-icons";
  return (
    <div className={"icon-button" + (props.printHide === undefined ? "" : " print-hide")}>
      <button className="icon-card" onClick={props.onClick}>
        {props.icon !== undefined && <i className={`${iconClass} idle`}>{props.icon}</i>}
        {props.iconHover !== undefined && <i className={`${iconClass} onhover`}>{props.iconHover}</i>}
        {props.image !== undefined && <img alt="" src={props.image} />}
        {props.text !== undefined && <div className="icon-button-text idle"><p>{props.text}</p></div>}
        {props.textHover !== undefined && <div className="icon-button-text onhover"><p>{props.textHover}</p></div>}
      </button>
      {props.hint !== undefined && <p className={"hint" + (props.isOdd !== undefined ?
        " hint-odd" : "")}>{props.hint}</p>}
      {props.canCopy !== undefined && <p className="copied">{data.copied}</p>}
    </div>
  );
}

// Buttons at the bottom for the navigation
// - title
// - link
function NavButton(props) {
  return (
    <div className={"nav-button-wrapper print-hide" + (props.canHide ? " phone-hide" : "")}>
      <div className="nav-button"
        onClick={() => props.external ? window.open(props.link, '_blank')
          : document.querySelector(props.link).scrollIntoView({behavior: 'smooth'})}>
        <p>{props.title}</p>
      </div>

      {!props.external && (
        <div className="arrow-down-wrapper">
          <div className="arrow-down"></div>
        </div>
      )}
    </div>
  );
}

function Home(props) {
  return (
    <div className="Home">
      <header>
          <IconButton image={lang === "en" ? fr : en}
            hint={data.translate}
            onClick={props.onChangeLang}
            printHide={true} />
          <IconButton text={data.print_pdf} hint={data.print_pdf}
            iconHover="download"
            onClick={downloadPrintableVersion}
            printHide={true} />

          <div className="spacer print-hide" />

          <IconButton icon="public" iconHover="open_in_new"
            iconOutlined={true} hint={data.website}
            onClick={() => window.open(data.url_website)} />
          <IconButton icon="mail" iconHover="open_in_new"
            iconOutlined={true} hint={data.contact}
            onClick={() => window.open(data.url_website_contact)} />
          <IconButton image={github}
            hint={App.printMode ?
              <a href={data.url_github}>GitHub</a>
              : "GitHub"}
            onClick={() => window.open(data.url_github)} />
          {/* <IconButton text="CF" textHover="CF"
            hint={App.printMode ?
              <a href={data.url_codeforces}>CodeForces</a>
              : "CodeForces"}
            onClick={() => window.open(data.url_codeforces)} /> */}
          <IconButton image={linkedin}
            hint={App.printMode ?
              <a href={data.url_linkedin}>LinkedIn</a>
              : "LinkedIn"}
            onClick={() => window.open(data.url_linkedin)} />
      </header>

      <div className="content">
        <div className="picture-wrapper">
          <div className="picture-card">
            <img alt="" className="picture" src={resources.profile} />
          </div>
        </div>

        <div className="title-wrapper">
          <h1>Celian Raimbault</h1>
        </div>
        <div className="subtitle-wrapper">
          <h2>{data.title}</h2>
        </div>
      </div>

      <nav>
        <NavButton title={data.projects} link="#projects" />
        {/* <NavButton title={data.website} link={data.url_website}
          external={true} /> */}
      </nav>
    </div>
  );
}

// Copy to clipboard
// function clipCopy(text) {
//   navigator.clipboard.writeText(text);
// }

function downloadPrintableVersion() {
  window.open(printData[lang]);
}

export default Home;
export {NavButton};
